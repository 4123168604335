import React from 'react';
import logo from "../../assets/images/logo-header.png";
import {Link} from 'react-router-dom';

function Header(props) {
    return (
        <>
            <header className="header_01 fixedHeader flipInX" id="header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2 col-sm-3 col-md-3 col-xs-8">
                            <div className="logo">
                                <Link to="/"><img src={logo} alt="cyber hybrid logo"/></Link>
                            </div>
                        </div>
                        <div className="col-lg-8 col-sm-7 col-md-7 m-auto">
                            <nav className="mainmenu text-center">
                                <ul>
                                    <li>
                                        <Link to="/">HOME</Link>
                                    </li>
                                    <li><Link to="/about">ABOUT</Link></li>
                                    <li><Link to="#">SERVICES</Link>

                                        <ul className="sub-menu">
                                            <li><Link to="/services/audit">Audit</Link></li>
                                            <li><Link to="/services/it-development">Development</Link></li>
                                            <li><Link to="/services/maintenance">Maintenance</Link></li>
                                            <li><Link to="/services/consulting">Consultancy</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to="/portofolio">PORTFOLIO</Link></li>
                                    <li><Link to="/contact">CONTACT</Link></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-lg-2 col-sm-2 col-md-2 col-xs-4 m-auto">
                            <div className="navigator text-right">
                                <Link to="#"
                                      className="menu mobilemenu hidden-sm hidden-md hidden-lg hidden-xs">
                                    <i className="mei-menu"/>
                                </Link>
                                <Link id="open-overlay-nav" className="menu hamburger" to="#">
                                    <i className="mei-menu"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;
