import React from 'react';
import journey from "../../assets/images/journey.png";

const Journey = () => {
    return (
        <>
            <section className="commonSection featured no-padding">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 aboutPicture" style={{backgroundImage: `url(${journey})`}}>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="row my-4">
                                        <div className="col-xs-2 col-md-2 col-lg-2 col-xl-3"/>
                                        <div className="col-xs-8 col-md-8 col-lg-8 col-xl-6">
                                            <div className="features_content">
                                                <h2 className="sec_title">OUR LIFECHANGING
                                                    JOURNEY</h2>
                                                <div className="singleFeature">
                                                    <div className="f_count">01</div>
                                                    <h3>23 MARCH 2019:JUST IMAGINE</h3>

                                                    <p>
                                                        Tanweer and Raihaan see a youtube video which triggers their
                                                        imagination.
                                                        They discuss the idea behind the video and relate this
                                                        problem to their own experiences.
                                                        The decision was made that it is realizable to solve this
                                                        problem. But they couldn’t do this alone.
                                                        We need Fey on the team.

                                                    </p>

                                                </div>
                                                <div className="singleFeature">
                                                    <div className="f_count">02</div>
                                                    <h3>30 MARCH 2019: HOW CAN WE DO THIS</h3>
                                                    <p>
                                                        having a meeting about the app at Fey’s home, looking for ways
                                                        to create the app.
                                                        We discussed the idea but knew that we were lacking IT
                                                        knowledge.
                                                        We decided to approach Ciprian.

                                                    </p>
                                                </div>
                                                <div className="singleFeature">
                                                    <div className="f_count">03</div>
                                                    <h3>20 APRIL 2019: DECISION DAY</h3>
                                                    <p>
                                                        We want to create an app (Ciprian came to the Netherlands).
                                                        As we introduced Cyprian to our idea, everything went
                                                        organically smooth. He liked the idea,
                                                        over a beachside diner we shook hands and confirmed to make this
                                                        our first big success as a team.

                                                    </p>
                                                </div>
                                                <div className="singleFeature">
                                                    <div className="f_count">04</div>
                                                    <h3>20 SEPTEMBER 2019: LIGHTS OFF, LIGHTS ON.</h3>
                                                    <p>
                                                        Discussing our struggles for creation off the app. To develop an
                                                        app to run our idea it would
                                                        cost us an excessive amount of money. There was only one way to
                                                        make this work. Let’s start an
                                                        IT company.
                                                    </p>
                                                </div>
                                                <div className="singleFeature">
                                                    <div className="f_count">05</div>
                                                    <h3>28 September 2019: from scratch to deadlines</h3>
                                                    <p>
                                                        The idea Cyber Hybrid was born. We held our first meeting at
                                                        Fey’s office.
                                                        Now it came to the point were abstract ideas needed to be
                                                        converted into action points.

                                                    </p>
                                                </div>
                                                <div className="singleFeature">
                                                    <div className="f_count">06</div>
                                                    <h3>28 NOVEMBER 2019: Our first business trip!</h3>
                                                            In 28 of November Fey and Raihaan arrived to Cluj Napoca.
                                                            Next day, the team comes together for the first meeting with a local lawyer and
                                                            the accountant, to gather all the information needed and think of the most
                                                            strategical moves.
                                                            In 30 of November and 1st of December they met some deadlines and they established
                                                            latest target points. Next they Fey and Raihaaan went back but happy to have
                                                            nice, productive team.
                                                </div>
                                                <div className="singleFeature">
                                                    <div className="f_count">07</div>
                                                    <h3>29 November 2019: Cluj day 1</h3>
                                                    <p>
                                                        The team comes together for the first meeting with their local
                                                        lawyer and the accountant
                                                        to gather all the information needed and think of the most
                                                        strategic moves.
                                                        After a long day of breaking their brains, they went to have
                                                        dinner together and some drinks
                                                        afterwards.

                                                    </p>
                                                </div>
                                                <div className="singleFeature">
                                                    <div className="f_count">08</div>
                                                    <h3>30 November 2019: Cluj day 2
                                                    </h3>
                                                    <p>
                                                        Starting the morning early with some sightseeing and a local
                                                        breakfast.
                                                        Thereafter the team meeting was planned at a beautiful location
                                                        ending the evening eating pizzas at the office and
                                                        decided to shut off with a night out celebrating a successful
                                                        business trip.

                                                    </p>
                                                </div>
                                                <div className="singleFeature">
                                                    <div className="f_count">09</div>
                                                    <h3>1 December 2019: Cluj day 3
                                                    </h3>
                                                    <p>
                                                        To end the weekend, the team comes together for a traditional
                                                        Irish breakfast.
                                                        while discussing the latest target points.
                                                    </p>
                                                </div>
                                                <div className="singleFeature">
                                                    <div className="f_count">10</div>
                                                    <h3>2 December 2019: All comes to an end
                                                    </h3>
                                                    <p>
                                                        Fey and Raihaan wake up early to prepare for the flight back to
                                                        Dortmund.
                                                        After a long felt flight with some so-called hardships ( maybe
                                                        we’ll tell you when we meet),
                                                        they landed in Dortmund and drove back home.
                                                    </p>
                                                </div>
                                                <div className="singleFeature">
                                                    <div className="f_count">11</div>
                                                    <h3>12 February 2020: Official incorporation Chamber of Commerce The
                                                        Netherlands
                                                    </h3>
                                                    <p>
                                                    </p>
                                                </div>
                                                <div className="singleFeature">
                                                    <div className="f_count">12</div>
                                                    <h3>soft launch
                                                    </h3>
                                                    <p>
                                                    </p>
                                                </div>
                                                <div className="singleFeature">
                                                    <div className="f_count">13</div>
                                                    <h3>1 sept 2020
                                                    </h3>
                                                    <p>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-2 col-md-2 col-lg-2 col-xl-3"/>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Journey;
