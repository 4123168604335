import React, {Component} from 'react';
import {Switch, Link, Route} from 'react-router-dom';
import './assets/css/bootstrap.css';
import './assets/css/owl.carousel.css';
import './assets/css/owl.theme.css';
import './assets/css/font-awesome.min.css';
import './assets/css/animate.css';
import './assets/css/magnific-popup.css';
import './assets/css/settings.css';
import './assets/css/slick.css';
import './assets/css/icons.css';
import './assets/css/preset.css';
import './assets/css/theme.css';
import './assets/css/custom.css';
import './assets/css/responsive.css';
import './assets/css/presets/color1.css';
import './assets/css/portofolio-slideshow.css';
import './global.css';

import Header from './components/Header';

import HomePage from './pages/HomePage/index';
import AboutPage from './pages/AboutPage';
import ServicesPage from './pages/ServicesPage';
import ContactPage from './pages/ContactPage';
import Theme from './components/Theme';
import Preloader from "./components/Preloader";
import {AnimatedSwitch} from "react-router-transition";
import ScrollToTop from "./components/ScrollToTop";
import PopupMenu from './components/PopupMenu';
import PrivacyPage from "./pages/PrivacyPolicyPage";
import FacebookChat from "./components/FacebookChat";
import ItDevelopmentPage from "./pages/ItDevelopmentPage";
import AuditPage from "./pages/AuditPage";
import MaintenencePage from "./pages/MaintenancePage";
import ConsultingPage from "./pages/ConsultingPage";
import PortofolioListPage from "./pages/PortofolioListPage";
import PortofolioItemPage from "./pages/PortofolioItemPage";
import DisclaimerPage from "./DisclaimerPage";
import CookiePolicyPage from "./pages/CookiePolicyPage";

export default class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            routes: [
                {
                    title: 'About',
                    key: 'about',
                    link: '/about',
                    component: AboutPage
                },
                {
                    title: 'Development',
                    key: 'it-development',
                    link: '/services/it-development',
                    component: ItDevelopmentPage
                },
                {
                    title: 'Audit',
                    key: 'audit',
                    link: '/services/audit',
                    component: AuditPage
                }, {
                    title: 'Maintenance',
                    key: 'maintenance',
                    link: '/services/maintenance',
                    component: MaintenencePage
                }, {
                    title: 'Consulting',
                    key: 'consulting',
                    link: '/services/consulting',
                    component: ConsultingPage
                },

                {
                    title: 'Contact',
                    key: 'contact',
                    link: '/contact',
                    component: ContactPage
                },
                {
                    title: 'Portofolio',
                    key: 'portofolio',
                    link: '/portofolio',
                    component: PortofolioListPage
                },
                {
                    title: 'Portofolio Detail Page',
                    key: 'portofolio item',
                    link: '/portofolio-detail/:projectId',
                    component: PortofolioItemPage
                },
                {
                    title: 'Privacy',
                    key: 'privacy',
                    link: '/privacy',
                    component: PrivacyPage
                },            {
                    title: 'Disclaimer',
                    key: 'disclaimer',
                    link: '/disclaimer',
                    component: DisclaimerPage
                },
                {
                    title: 'Terms & Conditions',
                    key: 'terms',
                    link: '/terms',
                    component: PrivacyPage
                },
                {
                    title: 'Cookie Policy',
                    key: 'cookie-policy',
                    link: '/cookie-policy',
                    component: CookiePolicyPage
                },
                {
                    title: 'Home',
                    key: 'home',
                    link: '*',
                    component: HomePage
                }

            ]
        };
    }

    componentDidMount() {

    }

    renderRoutes() {
        const {routes} = this.state;

        return routes.map(route => {
            const routeKey = `${route.key} ${route.title}`;
            return <Route exact key={routeKey} path={route.link} component={route.component}/>;
        });
    }

    render() {
        return (
            <>
                <ScrollToTop/>
                <Header/>
                <PopupMenu/>
                <Preloader/>
                <Theme/>
                <div className="page-container">
                    <AnimatedSwitch
                        atEnter={{opacity: 0,}}
                        atLeave={{opacity: 0}}
                        atActive={{opacity: 1}}
                        className="switch-wrapper">
                        {this.renderRoutes()}
                    </AnimatedSwitch>
                </div>
                <FacebookChat/>
            </>
        );
    }
};
