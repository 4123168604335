import React from "react";
import cyberRisks from "../../assets/images/audit/cyber-risks.png";

const ReportsOfCyberRisks = () => {
    return <>
        <section className="commonSection no-padding-bottom audit serviceDetailBackgroundBlack">
            <div className="row serviceDetailBackgroundWhite">
                <div className="col-12 aboutPicture" style={{backgroundImage: `url(${cyberRisks})`}}>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="row my-4">
                                <div className="col-xs-2 col-md-2 col-lg-2 col-xl-3"/>
                                <div className="col-xs-8 col-md-8 col-lg-8 col-xl-6">
                                    <div className="features_content">
                                        <h2 className="sec_title">Reports of cyber risks</h2>
                                        <div className="singleFeature">
                                            <div className="f_count">01</div>
                                            <h3>98% OF TESTED WEB APPS ARE VULNERABLE TO ATTACK</h3>
                                            <p>
                                                Trustwave’s 2015 Global Security Report found that a
                                                staggering
                                                98%
                                                of
                                                tested web applications were vulnerable to attack. Web apps
                                                are
                                                everywhere
                                                now, and it is essential that updates and patches are
                                                installed
                                                so
                                                that known
                                                vulnerabilities are addressed.
                                            </p>
                                        </div>
                                        <div className="singleFeature">
                                            <div className="f_count">02</div>
                                            <h3>90% OF LARGE ORGANISATIONS REPORTED SUFFERING A SECURITY
                                                BREACH</h3>
                                            <p>
                                                The Department of Business, Innovation & Skills’ 2015
                                                Information
                                                Security Breaches Survey was published at the beginning of
                                                June
                                                and
                                                was stuffed full of disturbing statistics. The report
                                                highlights
                                                how
                                                cyber attacks affect nearly every organisation, with 90% of
                                                large
                                                and 74% of small organisations suffering a breach in 2014
                                            </p>
                                        </div>
                                        <div className="singleFeature">
                                            <div className="f_count">03</div>
                                            <h3>75% OF DIRECTORS ARE NOT INVOLVED IN THE REVIEW OF CYBER
                                                SECURITY
                                                RISKS</h3>
                                            <p>
                                                We’ve written about this story a few times this year.
                                                Research
                                                undertaken by PwC for their 2015 Global State of Information
                                                Security Survey found that only 25% of directors are
                                                actively
                                                involved in reviewing security and privacy risks. Shocking
                                                behaviour.
                                            </p>
                                        </div>
                                        <div className="singleFeature">
                                            <div className="f_count">04</div>
                                            <h3>93% OF DPA BREACHES ARE CAUSED BY HUMAN ERROR</h3>
                                            <p>
                                                People: the weakest link in the cyber security chain. The
                                                Information
                                                Commissioner’s Office reported that 93% of incidents it
                                                investigated
                                                in Q4 of
                                                2014-15 were caused by human error.
                                            </p>
                                        </div>
                                        <div className="singleFeature">
                                            <div className="f_count">05</div>
                                            <h3>ONLINE BANKING FRAUD INCREASES 48% YEAR-ON-YEAR</h3>
                                            <p>
                                                Figures published in the first quarter of 2015 by Financial
                                                Fraud
                                                Action UK (FFA UK) found that losses from online banking
                                                fraud
                                                rose
                                                by 48% in 2014, costing £60.4 million. FFA UK identified a
                                                total
                                                of
                                                53,192 individual incidents.
                                                According to the FFA, “A key driver behind increasing levels
                                                of
                                                fraud continues to be fraudsters tricking customers into
                                                revealing
                                                personal and financial information, normally over the
                                                telephone.”
                                            </p>
                                        </div>
                                        <div className="singleFeature">
                                            <div className="f_count">06</div>
                                            <h3>144% INCREASE IN SUCCESSFUL CYBER ATTACKS ON BUSINESSES</h3>
                                            <p>
                                                CYREN’s 2015 Cyberthreat Yearbook report begins:
                                                “Enterprises of
                                                all
                                                sizes are now besieged by cybercrime at an alarming rate”.
                                                It
                                                found
                                                that successful cyber attacks on businesses of all sizes
                                                increased
                                                by 144% over a four-year period, adding further weight to
                                                the
                                                argument that organisations should now aim for cyber
                                                resilience:
                                                the
                                                ability to not only repel but also respond to a cyber
                                                attack.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-2 col-md-2 col-lg-2 col-xl-3"/>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6"/>
                    </div>
                </div>
            </div>

        </section>
    </>
}

export default ReportsOfCyberRisks
