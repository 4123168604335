import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import logo from "../../assets/images/logo.png";
const PopupMenu = () => {
    const {$} = window;

    const onRouteClick = () => {
        $('#close-popup').click();
    };

    return (
        <>
            <div className="popup popup__menu">
                <a href="" id="close-popup" className="close-popup"/>
                <div className="container mobileContainer">
                    <div className="row">
                        <div className="col-lg-12 text-left">
                            {/*<div className="logo2">*/}
                            {/*    <Link to="/"><img src={logo} alt="cyber hybrid logo"/></Link>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="popup-inner">
                                <div className="dl-menu__wrap dl-menuwrapper">
                                    {/*<nav>*/}
                                        <ul className="dl-menu dl-menuopen">
                                            <li>
                                               ` <Link to="/" onClick={onRouteClick}>HOME</Link>`
                                            </li>
                                            <li>
                                                <Link to="/about" onClick={onRouteClick}>ABOUT</Link>
                                            </li>
                                            <li className="menu-item-has-children">
                                                <Link to="#" >SERVICES</Link>
                                                <ul className="dl-submenu">
                                                    <li><Link to="/services/it-development">Development</Link></li>
                                                    <li><Link to="/services/audit">Audit</Link></li>
                                                    <li><Link to="/services/maintenance">Maintenance</Link></li>
                                                    <li><Link to="/services/consulting">Consultancy</Link></li>
                                                </ul>
                                            </li>
                                            <li><Link to="/portofolio" onClick={onRouteClick}>PORTFOLIO</Link></li>
                                            <li><Link to="/contact" onClick={onRouteClick}>CONTACT</Link></li>
                                        </ul>
                                    {/*</nav>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-sm-6 col-xs-12 text-left">
                            <ul className="footer__contacts text-left">
                                <li>Email: info@cyberhybrid.com</li>
                                <li>Phone: +31851304002</li>
                                <li>Address: The Hague Office
                                    World Trade Center The Hague
                                    Prinses Margrietplantsoen 33
                                    2595 AM The Hague
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-xs-12 col-xs-12">
                            <div className="popUp_social text-right">
                                <ul>
                                    <li><a href="https://twitter.com/hybrid_cyber"><i className="fa fa-twitter"></i>Twitter</a></li>
                                    <li><a href="https://www.facebook.com/Cyber-Hybrid-103051907856184/"><i className="fa fa-facebook-square"></i>Facebook</a></li>
                                    <li><a href=""><i className="fa fa-youtube-play"></i>Youtube</a></li>
                                    <li><a href="https://www.linkedin.com/company/31452855/"><i className="fa fa-linkedin"></i>Linkedin</a></li>
                                    <li><a href="https://www.instagram.com/cyberhybridnl/"><i className="fa fa-instagram"></i>Instagram</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PopupMenu;
