import React from 'react';
import mission from "../../assets/images/about/mission-vision.png"

const MissionVision = () => {
    return (
        <>
            <section className="commonSection mission-vision ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-6 col-md-6 PR_79">
                            <h4 className="sub_title">WHAT WE CARE ABOUT</h4>
                            <h2 className="sec_title MB_45">MISSION & VISION
                            </h2>
                            <p className="sec_desc">
                                Our mission is to facilitate growth for small and medium-sized  enterprises  through
                                our IT-services
                            </p>
                            <p className="sec_desc">
                                Our vision is to provide bulletproof IT-systems to companies, encompassing hardware,
                                software and education.
                            </p>
                            {/*<p className="sec_sub_title">OUR GOAL IS TO BRING IN 100K  REVENUE IN 2020</p>*/}
                        </div>
                        <div className="col-lg-6 col-sm-6 col-md-6">
                            <img src={mission} alt="" className="img_full_width"/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MissionVision;
