import React, {Component} from 'react';
import SearchForm from "../../components/SearchForm";
import Footer from "../../components/Footer";
import GetYourQuote from "../../components/GetYourQuote";
import PortofolioListImageBanner from "../../components/PortofolioListImageBanner";
import PortofolioListHeaderCategories from "../../components/PortofolioListHeaderCategories";
import PortofolioListCategoriesGrid from "../../components/PortofolioListCategoriesGrid";
import ConnectWithUs from "../../components/ConnectWithUs";

export default class PortofolioListPage extends Component {
    render() {
        return (
            <>
                <PortofolioListImageBanner title={"OUR PORTFOLIO"}/>
                {/*<PortofolioListHeaderCategories/>*/}
                <PortofolioListCategoriesGrid/>
                <SearchForm/>
                <GetYourQuote title={"LET’S GET YOUR PROJECT STARTED!"}/>
                <ConnectWithUs buttonText ={<>connect with us</>} title={<>Still not decided yet?</>}/>
                <Footer/>
            </>
        );
    }
};
