import axios from 'axios';

const instance = axios.create({
    baseURL: `${window.location.origin}/api`,
    timeout: 5000,
    headers: {}
});


export default instance
