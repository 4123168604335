import React from 'react';
import teamBio from "../../assets/images/about/team-bio.png"

const TeamBioDescription = () => {
    return (
        <>
            <section className="commonSection mission-vision no-padding">
                <div className="container d-sm-none">
                    <div className="row">
                        <div className="col-lg-8 col-sm-8 col-md-8 overlap-image">
                            <img src={teamBio} alt="" className="img_full_width"/>
                        </div>
                        <div className="col-lg-4 col-sm-4 col-md-4"/>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-5 col-sm-5 col-md-5"/>
                        <div className="col-lg-5 col-sm-5 col-md-5 commonSection pl-15 z-index mission-vision">
                            <h2 className="sec_title MB_45">TEAM BIO
                            </h2>
                            <p className="sec_desc">
                                Us founders have a very simple story that probably a lot of people can relate to.
                                Fey was a good finance entrepreneur lacking IT skills and insight into the industry.
                                Ciprian was a good IT developer but was lacking the entrepreneurial savviness. Raihaan
                                was a creative mind but lacked the tools and support system to realize his goals.
                                Tanweer was a finance student who had no passion for his education but has the skills to
                                achieve greater goals. We realized that there is no ‘’I’’ in team and we needed the four
                                of us to climb this mountain called the Tech industry.
                            </p>
                            <p className="sec_desc">
                                Our team is formed by four individuals with different passions, qualities and skills but
                                all with one collective vision. We will share our journey with the world to hopefully
                                inspire others to chase their dreams.
                            </p>
                        </div>
                        <div className="col-lg-2 col-sm-2 col-md-2"/>

                    </div>
                </div>
            </section>
        </>
    );
};

export default TeamBioDescription;
