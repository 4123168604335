import React, {Component} from 'react';
import SearchForm from "../../components/SearchForm";
import Footer from "../../components/Footer";
import GetYourQuote from "../../components/GetYourQuote";
import OurServiceDetail from "../../components/OurServiceDetail";
import PageTitle from "../../components/PageTitle";
import ReportsOfCyberRisks from "../../components/Audit/ReportsOfCyberRisks";

import image1 from "../../assets/images/audit/it-audit.png";
import image2 from "../../assets/images/audit/web-applications-security-report.png";
import image3 from "../../assets/images/audit/cost-assesment.png";
import image4 from "../../assets/images/audit/how-it-audit-work.png";
import ConnectWithUs from "../../components/ConnectWithUs";

export default class AuditPage extends Component {
    render() {
        return (
            <>
                <SearchForm/>
                <PageTitle
                    title={<>SERVICES</>}
                    subTitle={<>AUDIT</>}
                    sectionClassName="serviceDetailBackgroundBlack"
                />
                <OurServiceDetail classLeft="serviceDetailBackgroundBlack"
                                  classRight="serviceDetailBackgroundBlack white_font"
                                  childrenRight={<>
                                      <p className="sec_desc white_font">
                                          No matter what the size and type of your organization is, an audit offers
                                          crucial insights into the computing system’s overall functionality,
                                          possible security risks and various solutions available.
                                          <br/>
                                          <br/>
                                          The IT audit evaluates an entity’s information systems and the safeguards
                                          present to protect these systems. IT audits cover a variety of IT
                                          processing and communication infrastructure, including software
                                          applications, web services, operating systems, security systems, and
                                          client-server networks and systems.
                                          To reap the maximum benefits of IT auditing, you must realize its
                                          importance.
                                          <br/>
                                          <br/>
                                          This system first identifies the risks in an entity and then assesses them
                                          with the help of advanced design controls, therefore allowing the
                                          companies to think of an appropriate solution to tackle the threats. Thus,
                                          IT auditing is critical for companies and businesses looking to protect
                                          their IT system, valuable data and information.
                                          <br/>
                                          <br/>
                                          Due to the success of outsourcing IT services, the linking of IT systems
                                          and the emergence of information chains, dependence on IT services and
                                          systems is increasing. In addition to the media, politicians are also
                                          increasingly interfering with the digitization of the economy and society
                                          and new regulations are increasingly affecting the IT sector. These
                                          developments are increasing the demand from customers for security and
                                          transparency. It is therefore not surprising that the demand for an
                                          independent opinion on IT services is increasing. We at Cyber Hybrid
                                          provide that service.
                                      </p></>}
                                  childrenLeft={<>
                                      <img src={image1} className={"img_full_width"} alt=""/>
                                  </>}
                />

                <OurServiceDetail classLeft="serviceDetailBackgroundWhite"
                                  classRight="serviceDetailBackgroundWhite"
                                  childrenLeft={<>
                                      <h2 className="sec_title">Web applications Security Report</h2>
                                      <p className="sec_desc">
                                          Trustwave’s 2015 Global Security Report found that a staggering 98% of
                                          tested
                                          web
                                          applications were vulnerable to attack. Web apps are everywhere now,
                                          and
                                          it is
                                          essential
                                          that updates and patches are installed so that known vulnerabilities
                                          are
                                          addressed.
                                          <br/>
                                          <br/>
                                          Global Securiy Report found that a staggering 98% of tested web
                                          applications
                                          were
                                          vulnerable to attack. Even though web apps are widely used across al
                                          sectors
                                          of business,
                                          steps taken to secure these platfoms is severely lacking.
                                      </p></>}
                                  childrenRight={<>
                                      <img src={image2} className={"img_full_width"} alt=""/>
                                  </>}
                />

                <OurServiceDetail classLeft="serviceDetailBackgroundWhite"
                                  classRight="serviceDetailBackgroundWhite"
                                  childrenRight={<>
                                      <h2 className="sec_title">COST ASSESSMENT</h2>
                                      <p className="sec_desc">
                                          A survey of 2,410 IT decision-makers found that 54% of organizations
                                          are
                                          not
                                          measuring, and therefore don’t understand, the business costs of cyber
                                          risk.
                                          Lack of understanding results in businesses being unable to make
                                          risk-based
                                          business decisions backed by accurate and quantifiable metrics,
                                          resulting
                                          in a
                                          lack of actionable insight for the C-suite and board of directors.
                                          <br/>
                                          <br/>
                                          In 2017, cybercrime cost the global economy $600 billion. In 2018, the
                                          global
                                          financial damage exceeded $1 trillion, a 50% annual increase. In terms
                                          of
                                          scale, it is equal to the nominal GDP of Australia! The cost of
                                          cyberattacks
                                          is expected to grow exponentially in the years to come. As the digital
                                          economy
                                          expands, so does the threat landscape.
                                      </p></>}
                                  childrenLeft={<>
                                      <img src={image3} className={"img_full_width"} alt=""/>
                                  </>}
                />

                <OurServiceDetail classLeft="serviceDetailBackgroundBlack white_font"
                                  classRight="serviceDetailBackgroundBlack"
                                  childrenLeft={<>
                                      <h2 className="sec_title">how does this It-Audit Work?</h2>
                                      <p className="sec_desc">
                                          Cyber Hybrid offers experts who can carry out short-term,
                                          professional,
                                          clear
                                          and affordable
                                          audits. We are happy to advise your company on the quality of your
                                          ICT.
                                          This
                                          includes
                                          reliability, availability and security. In a general ICT audit, we
                                          conduct
                                          an
                                          expert, impartial and
                                          independent investigation in a short period of time. In this way, we
                                          hold
                                          up a
                                          mirror to your
                                          organization in terms of automation and we offer you the tools for
                                          better
                                          performance. An
                                          investment that pays for itself.
                                          <br/>
                                          <br/>
                                          So now you must be wondering, how does this work?
                                          First, we’ll perform an intake. This could be done in person or
                                          online.
                                          After
                                          that, we’ll perform
                                          the audit and after that, we will present our findings. We’ll state
                                          which
                                          aspects of your IT
                                          needs to be fixed and which ones can be improved. Lastly, we’ll offer
                                          our
                                          solutions to your
                                          problems. This is our 4-step approach
                                      </p></>}
                                  childrenRight={<>
                                      <img src={image4} className={"img_full_width"} alt=""/>
                                  </>}
                />

                <ReportsOfCyberRisks/>
                <GetYourQuote title={"LET’S GET YOUR PROJECT STARTED!"}/>
                <ConnectWithUs buttonText ={<>connect with us</>} title={<>Still not decided yet?</>}/>
                <Footer/>
            </>
        );
    }
};
