import React from 'react';

const DisclaimerContent = () => {
    return (
        <div  style={{marginLeft: '50px', marginRight: '50px'}}>>
            <p dir="ltr" style={{lineHeight: '1.2',marginTop: '250px', marginBottom: '0pt'}}><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}><br /><br /></span></p>
            <p dir="ltr" style={{lineHeight: '1.2', textAlign: 'center', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '18pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>DISCLAIMER</span></p>
            <p><br /></p>
            <p><br /></p>
            <p dir="ltr" style={{lineHeight: '1.2', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>This Website www.cyberhybrid.com (the “</span><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Website</span><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>”) is property of Cyber Hybrid BV (hereafter to be named “</span><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Cyber Hybrid</span><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>”). Cyber Hybrid is registered at the Chamber of Commerce under number 77338499.</span></p>
            <p dir="ltr" style={{lineHeight: '1.2', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}><br /></span><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>General&nbsp;</span></p>
            <p dir="ltr" style={{lineHeight: '1.2', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}><br /></span><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>The following is applicable to the Website. By using the Website you agree with this disclaimer.&nbsp;</span><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}><br /><br /></span></p>
            <p dir="ltr" style={{lineHeight: '1.2', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Content Website</span></p>
            <p><br /></p>
            <p dir="ltr" style={{lineHeight: '1.2', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Cyber Hybrid reserves the right to alter the contents of the Website and this disclaimer at any time or remove it without having any obligation to notify you thereof.</span></p>
            <p dir="ltr" style={{lineHeight: '1.2', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}><br /></span><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Cyber Hybrid puts care and attention to the content of the Website. However the use of (information on) the Website is at your own risk. Despite our efforts, it may occur that the content of the Website is incomplete, outdated and/or incorrect. Therefore the content of the Website is provided to you without any form of guarantee or liability on its accuracy.&nbsp;</span><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}><br /><br /></span></p>
            <p dir="ltr" style={{lineHeight: '1.2', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>All prices displayed at the Website are subject to obvious mistakes (</span><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'italic', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>kennelijke fouten</span><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>) for example caused by incorrect entries or technical problems. Cyber Hybrid is not liable for the consequences of such errors. On the basis of such errors, no agreement will be concluded between you and Cyber Hybrid.</span></p>
            <p><br /></p>
            <p dir="ltr" style={{lineHeight: '1.2', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Liability</span></p>
            <p><br /></p>
            <p dir="ltr" style={{lineHeight: '1.2', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Cyber Hybrid is not liable for any damage or other harmful consequences by the use of (information on) the Website. It is at your own risk if you undertake any action based on (the information of) our Website. No agreement will be concluded between you and Cyber Hybrid on the basis of obvious mistakes and/or incomplete or outdated information.</span></p>
            <p><br /></p>
            <p dir="ltr" style={{lineHeight: '1.2', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Cyber Hybrid is not liable if the Website is not accessible or available due to malfunction (</span><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'italic', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>storing</span><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>) and is not responsible or liable for malfunctions in networks of third parties, by means of which the access to the Website is obtained. In addition, Cyber Hybrid is not liable for any content of the Website which is provided by third parties.</span></p>
            <p><br /></p>
            <p dir="ltr" style={{lineHeight: '1.2', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>The Website includes hyper links to websites of third parties. Cyber Hybrid does not accept any liability with respect to (the content of) these websites and/or services of third parties.</span></p>
            <p><br /></p>
            <p><br /></p>
            <p dir="ltr" style={{lineHeight: '1.2', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Intellectual Property</span></p>
            <p><br /></p>
            <p dir="ltr" style={{lineHeight: '1.2', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>All intellectual property rights with respect to the Website remain with Cyber Hybrid. Without the prior written consent of Cyber Hybrid it is not allowed to disclose, multifold, save or offer for sale (parts of) the Website, information on the Website or other material displayed on the Website, in any possible way.&nbsp;</span></p>
            <p><br /></p>
            <p dir="ltr" style={{lineHeight: '1.2', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Contact</span></p>
            <p><br /></p>
            <p dir="ltr" style={{lineHeight: '1.2', marginTop: '0pt', marginBottom: '50px'}}><span style={{fontSize: '10pt', fontFamily: 'Arial', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Should you have any further questions and/or comments in relation to this disclaimer, you can contact Cyber Hybrid via info@cyberhybrid.com.</span></p>
        </div>
    );
}

export default DisclaimerContent;
