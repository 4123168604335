import React from 'react';
import image1 from "../../assets/images/team/1.png";
import image2 from "../../assets/images/team/2.png";
import image3 from "../../assets/images/team/3.png";
import image4 from "../../assets/images/team/4.png";
import image5 from "../../assets/images/team/5.png";

const Team = () => {
    return (
        <>
            <section className="commonSection team">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h4 className="sub_title">MEET THE TEAM</h4>
                            <h2 className="sec_title">expert people</h2>
                            <p className="sec_desc">
                                We are committed to providing our customers with exceptional service
                            </p>
                        </div>
                    </div>
                </div>
                <div className="team_slider">
                    <div className="singleTM">
                        <div className="tm_img">
                            <img src={image1} alt=""/>
                        </div>
                        <div className="detail_TM">
                            <h5>VLAD COTFAS</h5>
                            <h6>Head of Android Development</h6>
                        </div>
                    </div>
                    <div className="singleTM">
                        <div className="tm_img">
                            <img src={image2}  alt=""/>
                        </div>
                        <div className="detail_TM">
                            <h5>Cristian Raica</h5>
                            <h6>Head of iOS Development</h6>
                        </div>
                    </div>
                    <div className="singleTM">
                        <div className="tm_img">
                            <img src={image3} alt=""/>
                        </div>
                        <div className="detail_TM">
                            <h5>Rares Pasca</h5>
                            <h6>head of Back-end Development</h6>
                        </div>
                    </div>
                    {/*<div className="singleTM">*/}
                    {/*    <div className="tm_img">*/}
                    {/*        <img src={image4}  alt=""/>*/}
                    {/*    </div>*/}
                    {/*    <div className="detail_TM">*/}
                    {/*        <h5>Ciprian Lupu</h5>*/}
                    {/*        <h6>CTO</h6>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="singleTM">
                        <div className="tm_img">
                            <img src={image5}  alt=""/>
                        </div>
                        <div className="detail_TM">
                            <h5>Raluca Papoi</h5>
                            <h6>Creative Director</h6>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Team;
