import React from 'react';
import image1 from "../../assets/images/about/about_us.png";

const AboutUs = () => {
    return (
        <>
            <section className="commonSection ab_agency no-padding-bottom about-us">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 PR_79 aboutUsPicture" style={{backgroundImage:`url(${image1})`}}>
                            <div className="row about-us-padding-bottom text-left">
                                <div className="col-sm-6">
                                    <h4 className="sub_title">ABOUT US</h4>
                                    <h2 className="sec_title br MB_45">HOW IT STARTED <br/>
                                        LET’S MEET THE <br/>
                                        GUYS FIRST</h2>
                                </div>
                            </div>
                            <div className="row about-us-padding-top">
                                <div className="col-sm-6"/>
                                <div className="col-sm-6 text-right">
                                        <h4 className="sub_title">COMPANY STARTED</h4>
                                        <h2>2020</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutUs;
