import React, {Component} from 'react';
import Testimonials from "../../components/Testimonials";
import SearchForm from "../../components/SearchForm";
import PopupMenu from "../../components/PopupMenu";
import Services from "../../components/Services";
import BestAgency from "../../components/BestAgency";
import FiveReasons from "../../components/FiveReaseons";
import Team from "../../components/Team";
import Footer from "../../components/Footer";
import GetYourQuote from "../../components/GetYourQuote";
import HomePageBanner from "../../components/HomePageBanner";
import ConnectWithUs from "../../components/ConnectWithUs";
import PortfolioSlideShow from "../../components/PortfolioSlideshow";

import biziday from "../../assets/images/portfolio/slider/biziday.jpg";
import delfine from "../../assets/images/portfolio/slider/delfine.jpg";
import adauu from "../../assets/images/portfolio/slider/adau.jpg";

export default class HomePage extends Component {
    render() {
        const slides = [
            {
                img: biziday,
            },
            {
                img: delfine,
            },
            {
                img: adauu,
            }
        ];
        return (

            <>
                <SearchForm/>
                <HomePageBanner/>

                <Services/>
                <PortfolioSlideShow slides={slides}/>
                <FiveReasons/>
                <Team/>
                <GetYourQuote title={"LET’S GET YOUR PROJECT STARTED!"}/>
                {/*<GetYourQuote title={<>IF YOU LIKE OUR STORY,<br/>YOU’LL LOVE TO WORK WITH US!</>}/>*/}
                <ConnectWithUs buttonText ={<>connect with us</>} title={<>Still not decided yet?</>}/>
                <Footer/>
            </>
        );
    }
};
