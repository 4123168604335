import React, {Component} from 'react';
import SearchForm from "../../components/SearchForm";
import Footer from "../../components/Footer";
import GetYourQuote from "../../components/GetYourQuote";
import PageTitle from "../../components/PageTitle";
import OurServiceDetail from "../../components/OurServiceDetail";
import image2 from "../../assets/images/home/product_market_strategy.png";
import image3 from "../../assets/images/home/mobile_mvp_info.png";
import ConnectWithUs from "../../components/ConnectWithUs";

export default class ItDevelopmentPage extends Component {
    render() {
        return (
            <>
                <SearchForm/>
                <PageTitle
                    title={<>SERVICES</>}
                    subTitle={<>Development</>}
                    sectionClassName="serviceDetailBackgroundBlack"
                />
                <OurServiceDetail classLeft="serviceDetailBackgroundWhite" classRight="serviceDetailBackgroundGray"
                                  childrenLeft={<>
                                      <h2 className="sec_title">MOBILE APP
                                          DEVELOPMENT</h2>
                                      <p className="sec_desc">
                                          To build native means to use all the capabilities of the mobile devices.
                                          It
                                          means not
                                          to compromise on experience you create for the user. And eventually means
                                          a
                                          solid
                                          foundation for scaling up and turning a mobile product into a real
                                          business.
                                          We use
                                          Swift for iOS and Kotlin for Android, with a sheer focus on performance
                                          and
                                          clean
                                          code.
                                      </p></>}
                                  childrenRight={<>
                                      <ul className={"services_list"}>
                                          <li>Android native development (Kotlin & Java)
                                          </li>
                                          <li>IOS native development(Swift)
                                          </li>
                                          <li>React-Native development (crossplatform)
                                          </li>
                                          <li>Flutter development (crossplatform)
                                          </li>
                                      </ul>
                                  </>}
                />
                <OurServiceDetail classLeft="serviceDetailBackgroundBlack white_font"
                                  classRight="serviceDetailBackgroundWhite"
                                  childrenLeft={<>
                                      <h2 className="sec_title">WEB
                                          DEVELOPMENT
                                      </h2>
                                      <p className="sec_desc white_font">
                                          Web components are core parts of full stack mobile products. Our internal
                                          team
                                          can
                                          help you build Web Admins, Content Management Systems or small CRMS.
                                      </p></>}
                                  childrenRight={<>
                                      <ul className={"services_list"}>
                                          <li>
                                              React Development
                                          </li>
                                          <li>
                                              Vue.js Development
                                          </li>
                                          <li>Angular Development
                                          </li>
                                      </ul>
                                  </>}
                />
                <OurServiceDetail classLeft="serviceDetailBackgroundWhite"
                                  classRight={"serviceDetailBackgroundGray"} childrenLeft={<>
                    <h2 className="sec_title">BACKEND SERVICES</h2>
                    <p className="sec_desc">
                        Our focus to build full-stack mobile products meant hiring experienced backend and
                        web developers to complete the rest of the product team. And that paid up a lot, as
                        now we can have integrated teams in house, covering all the components of your
                        product, from front-end to back-end, from design to product management. And you
                        don’t really know what difference that makes in delivering projects in time and
                        budget until you’ve done it.
                    </p></>}
                                  childrenRight={<>
                                      <ul className={"services_list"}>


                                          <li>
                                              API development using Node.js® or PHP
                                          </li>
                                          <li>
                                              Database solutions using MongoDB or MySQL
                                          </li>
                                          <li>RESTful JSON API
                                          </li>
                                          <li>
                                              Full documentation
                                          </li>
                                          <li>
                                              Hosting with Amazon Web Services, Google Cloud or Heroku
                                          </li>
                                      </ul>
                                  </>}
                />
                <OurServiceDetail classLeft="serviceDetailBackgroundBlack white_font" childrenLeft={<>
                    <h2 className="sec_title">UX & UI
                        DESIGN</h2>
                    <p className="sec_desc white_font">
                        We try to design products that solve specific needs for the users. That means
                        understanding the users first and building empathy before anything else. Our UX & UI
                        designs work closely with you to go from understanding the experiences we need to
                        create for the users, to building the wireframes, prototyping and doing a remarkable
                        user interface. The visible UI design is just the tip of a deep iceberg, where the real
                        value sits.
                    </p></>}
                                  childrenRight={<>
                                      <ul className={"services_list"}>
                                          <li>
                                              Wireframing & Prototyping
                                          </li>
                                          <li>
                                              Design explorations
                                          </li>
                                          <li>Full UI design creation
                                          </li>
                                          <li>
                                              Identity & App Icons
                                          </li>
                                          <li>
                                              Graphic assets for app stores
                                          </li>
                                      </ul>
                                  </>}
                />
                <OurServiceDetail classLeft="serviceDetailBackgroundWhite" classRight="serviceDetailBackgroundGray"
                                  childrenLeft={<>
                                      <h2 className="sec_title">PRODUCT &
                                          MARKET STRATEGY</h2>
                                      <p className="sec_desc">
                                          We came up with a mechanism to ensure our partners are totally prepared
                                          for the journey ahead.<br/>
                                          Within the Product Discovery Workshop we fully define the mobile product
                                          to be built. It’s a scoping
                                          session, combined with product road-mapping, where we go into detail on
                                          every aspect of the app:
                                          UI/UX design, technological implications, and the overall business model
                                          setup.<br/>
                                          These sessions provide direction, clarity and regulate expectations about
                                          the development of the client’s mobile project.
                                      </p></>}
                                  childrenRight={<>
                                      <img src={image2} className={"img_full_width"} alt=""/>
                                  </>}
                />
                <OurServiceDetail classLeft="serviceDetailBackgroundBlack white_font"
                                  classRight="serviceDetailBackgroundBlack"
                                  childrenLeft={<>
                                      <h2 className="sec_title">MOBILE MVP</h2>
                                      <p className="sec_desc white_font">
                                          Obviously you know this: the hard (and sad) truth is that the original
                                          idea rarely
                                          survives its first contact with the users. Don’t let yourself fall in
                                          the typical startup
                                          trap: investing too many resources, for too long, in some app features
                                          your audience
                                          won’t be really using.

                                          So what’s the alternative? It’s called a Mobile MVP and it’s what we
                                          advise all of our
                                          startup partners to begin with: a minimum viable mobile product to set
                                          the record
                                          straight on what needs to be built.

                                          The Mobile MVP is all about the core features your app needs to have to
                                          fulfill its
                                          purpose. It’s a strip down of any other features except the basic ones,
                                          in order for
                                          you to get real, valuable user feedback.
                                      </p></>}
                                  childrenRight={<>
                                      <img src={image3} className={"img_full_width"} alt=""/>
                                  </>}
                />
                <GetYourQuote title={"LET’S GET YOUR PROJECT STARTED!"}/>
                <ConnectWithUs buttonText={<>connect with us</>} title={<>Still not decided yet?</>}/>
                <Footer/>
            </>
        );
    }
};
