import React, {Component} from 'react';
import SearchForm from "../../components/SearchForm";
import PopupMenu from "../../components/PopupMenu";
import Footer from "../../components/Footer";
import Journey from "../../components/Journey";
import AboutUs from "../../components/AboutUs";
import TeamBio from "../../components/TeamBio";
import GetYourQuote from "../../components/GetYourQuote";
import MissionVision from "../../components/MissionVision";
import Values from "../../components/Values";
import TeamBioDescription from "../../components/TeamBioDescription";
import ConnectWithUs from "../../components/ConnectWithUs";

export default class AboutPage extends Component {
    render() {
        return (
            <>
                <SearchForm/>
                <AboutUs/>
                <TeamBio/>
                <MissionVision/>
                <Values/>
                <TeamBioDescription/>
                <Journey/>
                <GetYourQuote title={<>IF YOU LIKE OUR STORY,<br/>YOU’LL LOVE TO WORK WITH US!</>}/>
                <ConnectWithUs buttonText ={<>connect with us</>} title={<>Still not decided yet?</>}/>
                <Footer/>

            </>
        );
    }
};
