import React from 'react';
import image from "../../assets/images/home_1/5_reasons.png";
const FiveReasons = () => {
    return (
        <>
            <section className="service_section commonSection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h4 className="sub_title red_color">NOT DECIDED?</h4>
                            <h2 className="sec_title white">5 REASONS TO CHOOSE<br/> CYBER HYBRID
                            </h2>

                        </div>
                    </div>
                    <div className="col-lg-5 col-sm-5 col-md-5">
                        <div className="chose_img">
                            <img src={image} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-7 col-sm-7 col-md-7">
                        <div className="wh_choose">
                            <ul className={"white_font"}>
                                <li><i className="fa fa-check-square"></i>Senior-developers only
                                </li>
                                <li><i className="fa fa-check-square"></i>Vulnerable transparency
                                </li>
                                <li><i className="fa fa-check-square"></i>Global reach
                                </li>
                                <li><i className="fa fa-check-square"></i>Leading edge technology</li>
                                <li><i className="fa fa-check-square"></i>In-house designing
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </section>
        </>
    );
};

export default FiveReasons;
