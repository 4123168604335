import React, {Component} from 'react';
import SearchForm from "../../components/SearchForm";
import Footer from "../../components/Footer";
import GetYourQuote from "../../components/GetYourQuote";
import PageTitle from "../../components/PageTitle";
import OurServiceDetail from "../../components/OurServiceDetail";
import image1 from "../../assets/images/consulting/consultancy.png";
import image2 from "../../assets/images/consulting/reason-to-hire-a-consultant.png";
import image3 from "../../assets/images/consulting/real-cost-of-hiring-a-consultant.png";
import image4 from "../../assets/images/consulting/what-consultants-can-mean.png";
import ConnectWithUs from "../../components/ConnectWithUs";

export default class ConsultingPage extends Component {
    render() {
        return (
            <>
                <SearchForm/>
                <PageTitle
                    title={<>SERVICES</>}
                    subTitle={<>CONSULTANCY</>}
                    sectionClassName="serviceDetailBackgroundBlack"
                />
                <OurServiceDetail classLeft="serviceDetailBackgroundBlack"
                                  classRight="serviceDetailBackgroundBlack white_font"
                                  childrenRight={<>
                                      <p className="sec_desc white_font">
                                          In the current economic environment, it is important that businesses find ways
                                          to stand out, tighten their operations, and increase their revenues while
                                          keeping expenses low. Businesses have had to adapt to different ways of
                                          marketing products, think of social media channels, online advertising etc.
                                          These new technology solutions can prove a hurdle too big to overcome for many
                                          businesses. On top of the hurdles that the Covid-19 pandemic put in front of
                                          us.
                                          A lot more businesses are adapting ways to let their employees work remotely,
                                          be it either fully or partially.
                                          <br/>
                                          <br/>
                                          Herein lies great opportunity for businesses provided the systems in place;
                                          management, technology and finances are optimally implemented.
                                          But what if they are not? This is where we come in. Cyber Hybrid would like to
                                          be your guide putting you on the right path of great revenue and cost
                                          efficiency during these turbulent times.
                                          <br/>
                                          <br/>
                                          Our team of financial and IT experts can help you grow your business. As a
                                          consultant we have an objective perspective and aid you in taking the right
                                          decisions. This could be one of the reasons for hiring a consultant but there
                                          are of course many more.
                                      </p></>}
                                  childrenLeft={<>
                                      <img src={image1} className={"img_full_width"} alt=""/>
                                  </>}
                />

                <OurServiceDetail classLeft="serviceDetailBackgroundWhite"
                                  classRight="serviceDetailBackgroundWhite"
                                  childrenLeft={<>
                                      <h2 className="sec_title">Reasons to hire a consultant</h2>
                                      <p className="sec_desc">
                                          Business consultants can range from certified public accountants, attorneys
                                          who are skilled in business law, or consulting firms who offer services that
                                          apply to a larger range of business related activities such as management,
                                          sales, or marketing. The number one benefit that consultants can offer to
                                          businesses is that they can provide temporary expertise, contrary to a
                                          full-time employee and the costs pertaining to employment.
                                          <br/>
                                          <br/>
                                          Cyber Hybrid has a broad network of partners in many fields of business and as
                                          such are always aware of the latest trends, in the field of marketing,
                                          finance, management or technology. Perhaps you would like to change the way
                                          you do business but don’t know how to go about it IT-wise. Our experts will
                                          translate your problem to an innovative and practical solution.
                                          <br/>
                                          <br/>
                                          We as entrepreneurs ourselves understand the difficulties you face and will
                                          share our thoughts on ways to improve your business that you haven’t
                                          considered yet.
                                      </p></>}
                                  childrenRight={<>
                                      <img src={image2} className={"img_full_width"} alt=""/>
                                  </>}
                />

                <OurServiceDetail classLeft="serviceDetailBackgroundWhite"
                                  classRight="serviceDetailBackgroundWhite"
                                  childrenRight={<>
                                      <h2 className="sec_title">The real cost of hiring a consultant</h2>
                                      <p className="sec_desc">
                                          Hiring a consultant allows firms the ability to pay only for the services they
                                          need, rather than investing in pricey technologies or paying to keep staff on
                                          hand that may not always be needed. In addition, hiring a consultant enables
                                          cost benefits associated with a lack of taxes, the need to pay benefits or
                                          human resources issues that are associated with hiring a new full-time
                                          employee. The costs involved in hiring a consultant are also scalable, so the
                                          firm is able to track costs and match them to the projects completed by the
                                          consultant. This allows the firm to analyze the value provided by the
                                          consultant. Finally, when the firm no longer needs the consultant’s services,
                                          the contract is easily terminated.
                                      </p></>}
                                  childrenLeft={<>
                                      <img src={image3} className={"img_full_width"} alt=""/>
                                  </>}
                />

                <OurServiceDetail classLeft="serviceDetailBackgroundBlack white_font"
                                  classRight="serviceDetailBackgroundBlack"
                                  childrenLeft={<>
                                      <h2 className="sec_title">What consultants can mean for your business</h2>
                                      <p className="sec_desc">
                                          Consultants provide a significant amount of value for an organization. They
                                          can help to develop strategies for growth or manage projects. Since
                                          consultants are not committed to a single firm, they bring experience from a
                                          variety of companies and industries, which allows them to offer creative
                                          solutions and enables “out of the box thinking”. They can provide an objective
                                          viewpoint, which allows for more diverse ideas than could be provided solely
                                          by employees within the organization. A consultant may have a higher level of
                                          business expertise than the average employee and can provide unique solutions
                                          for businesses.
                                      </p></>}
                                  childrenRight={<>
                                      <img src={image4} className={"img_full_width"} alt=""/>
                                  </>}
                />
                <GetYourQuote title={"LET’S GET YOUR PROJECT STARTED!"}/>
                <ConnectWithUs buttonText ={<>connect with us</>} title={<>Still not decided yet?</>}/>

                {/*<GetYourQuote*/}
                {/*    buttonText= {<>Let’s get your project started!</>}*/}
                {/*    title={<>Get your quote now.</>}*/}
                {/*    description={<>This all while keeping the culture and core-values of the business. During the*/}
                {/*        consultation we will communicate openly and frequently and won’t hesitate to ask questions to*/}
                {/*        ensure the most effective solution has been presented. We hope to welcome you as a partner in*/}
                {/*        business letting us help you grow yours.*/}
                {/*    </>}/>*/}
                {/*<GetYourQuote title={"LET’S GET YOUR PROJECT STARTED!"}/>*/}
                <Footer/>
            </>
        );
    }
};
