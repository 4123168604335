import React from 'react';

const ImageBanner = () => {
    return (
        <>
            <>
                <section className="imageBanner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="banner_content text-center">
                                    <h4>Welcome to Cyberhybrid</h4>
                                    <h2>Mobile App<br/>
                                        Development Services
                                    </h2>
                                    <p className={"margin-top-subtitle"}>We cover all the bits and pieces needed to develop
                                        a top-notch, innovative mobile product, from strategy, to design and coding all the product components.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </>
    );
};

export default ImageBanner;
