import React from 'react';

const SearchForm = () => {
    return (
        <>
            <div className="searchFixed popupBG">
                <div className="container-fluid">
                    <a href="" id="sfCloser" className="sfCloser"/>
                    <div className="searchForms">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-lg-offset-2">
                                    <form method="post" action="#">
                                        <input type="text" name="s" className="searchField" placeholder="Search here..."/>
                                        <button type="submit"><i className="fa fa-search"/></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SearchForm;
