import React, {Component} from 'react';
import Testimonials from "../../components/Testimonials";
import SearchForm from "../../components/SearchForm";
import PopupMenu from "../../components/PopupMenu";
import Services from "../../components/Services";
import BestAgency from "../../components/BestAgency";
import FiveReasons from "../../components/FiveReaseons";
import Team from "../../components/Team";
import Footer from "../../components/Footer";
import GetYourQuote from "../../components/GetYourQuote";
import HomePageBanner from "../../components/HomePageBanner";
import PortofolioListHeaderCategories from "../../components/PortofolioListHeaderCategories";
import PortofolioListImageBanner from "../../components/PortofolioListImageBanner";
import PortofolioDetailSection from "../../components/PortofolioDetailSection";
import data from "../../json/portofolio";
import ConnectWithUs from "../../components/ConnectWithUs";

const portofolioItems = data;
export default class PortofolioItemPage extends Component {
    render() {
        const {match: {params}} = this.props;
        const currentProjectKey = `${params.projectId}`;
        let currentProject = portofolioItems.Projects.filter(function (project, index) {
            return project.key === currentProjectKey
        })[0];
        return (
            <>
                <SearchForm/>
                <PortofolioListImageBanner title={`${currentProject.name}`}/>
                <PortofolioDetailSection project={currentProject}/>
                <GetYourQuote title={"LET’S GET YOUR PROJECT STARTED!"}/>
                <ConnectWithUs buttonText ={<>connect with us</>} title={<>Still not decided yet?</>}/>
                <Footer/>
            </>
        );
    }
};

