import React, {Component} from 'react';
import SearchForm from "../../components/SearchForm";
import Footer from "../../components/Footer";
import GetYourQuote from "../../components/GetYourQuote";
import PageTitle from "../../components/PageTitle";
import OurServiceDetail from "../../components/OurServiceDetail";
import image1 from "../../assets/images/mainteinance/mainteinance.png";
import image2 from "../../assets/images/mainteinance/the-purpose-of-mainteinance.png";
import ConnectWithUs from "../../components/ConnectWithUs";

export default class MaintenancePage extends Component {
    render() {
        return (
            <>
                <SearchForm/>
                <PageTitle
                    title={<>SERVICES</>}
                    subTitle={<>Maintenance</>}
                    sectionClassName="serviceDetailBackgroundBlack"
                />
                <OurServiceDetail classLeft="serviceDetailBackgroundBlack"
                                  classRight="serviceDetailBackgroundBlack white_font"
                                  childrenRight={<>
                                      <p className="sec_desc white_font">
                                          When working with small businesses we so often hear,’’ technology is great
                                          until it doesn’t work.’’ when it comes to your business, there is nothing
                                          worse than downtime because your technology isn’t working properly. So how do
                                          you keep your technology working? After all, no one wants to be stuck in the
                                          stone age right?
                                          <br/>
                                          <br/>
                                          The answer is maintenance.
                                          <br/>
                                          <br/>
                                          There are two mindsets in the world when it comes to maintenance, reactive and
                                          preventative:
                                          <br/>
                                          <br/>
                                          1. Reactive maintenance goes with the idea of ‘’if it isn’t broken why fix
                                          it?’’ while this can save money in the short term, in the long run, it can end
                                          up costing considerably more, especially for a relatively small business.
                                          <br/>
                                          <br/>
                                          2. Peventative maintenance, on the other hand, is doing regular upkeep to
                                          technology and systems. This includes updates, patches, security verifying
                                          backup data, scans etc.
                                      </p></>}
                                  childrenLeft={<>
                                      <img src={image1} className={"img_full_width"} alt=""/>
                                  </>}
                />

                <OurServiceDetail classLeft="serviceDetailBackgroundWhite"
                                  classRight="serviceDetailBackgroundWhite "
                                  childrenLeft={<>
                                      <h2 className="sec_title">the purpose of maintenance</h2>
                                      <p className="sec_desc">
                                          The main purpose of software maintenance is to modify and update software
                                          applications after delivery to correct faults and to improve performance.
                                          Software Maintenance must be performed in order to:
                                      </p>
                                      <ul>
                                          <li>
                                              Correct faults.
                                          </li>
                                          <li>
                                              Improve design.
                                          </li>
                                          <li>
                                              Implement enhancements.
                                          </li>
                                          <li>
                                              Interface with other systems.
                                          </li>
                                          <li>
                                              Accommodate programs so that different hardware, software, system
                                              features,
                                              and telecommunications facilities can be used.
                                          </li>
                                          <li>
                                              Migrate legacy software.
                                          </li>
                                          <li>
                                              Retire software.
                                          </li>
                                      </ul>

                                      <p className="sec_desc">
                                          Given how crucial IT is to the growth of your business, we understand that any
                                          malfunctions can rapidly result in disruption of operations, leading to losses
                                          in revenue, time and more.
                                          That’s where we come in. We at CyberHybrid are uniquely qualified to maintain
                                          the IT that you utilize. We employ a team of Senior-developers that can tackle
                                          every obstacle thrown at them and we have one simple promise:
                                          Response to any query within 24 hours.
                                      </p></>}
                                  childrenRight={<>
                                      <img src={image2} className={"img_full_width"} alt=""/>
                                  </>}
                />

                <GetYourQuote title={"LET’S GET YOUR PROJECT STARTED!"}/>
                <ConnectWithUs buttonText ={<>connect with us</>} title={<>Still not decided yet?</>}/>
                <Footer/>
            </>
        );
    }
};
