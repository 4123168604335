import React from 'react';
import data from "../../json/portofolio";
import {Link} from "react-router-dom";

const portofolioItems = data;
const PortofolioListCategoriesGrid = () => {
    return (
        <>
            <section className="portofolioListCategoriesFilterContainer">
                <div className="row" id="Grid">
                    <div className="">
                        {
                            portofolioItems.Projects.map((item) => {
                                return (
                                    <Link to={`/portofolio-detail/${item.key}`}>
                                        <div
                                            className={`col-lg-4 col-sm-6 col-md-4 mix mix_all portofolioItemContainer ${item.category}`}>
                                            <div className="singlefolio">
                                                <img src={require(`../../assets/images/portfolio/${item.mainImage}`)}
                                                     alt=""/>
                                                <div className="folioHover">
                                                    <h4>{item.category}</h4>
                                                    <br/>
                                                    <h3>
                                                        {item.name}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                );
                            })
                        }
                    </div>
                </div>
            </section>
        </>
    );
};

export default PortofolioListCategoriesGrid;
