import React from 'react';
import test from "../../assets/images/team/2.png";
import fey from "../../assets/images/about/fey.jpg";
import raihan from "../../assets/images/about/raihan.jpg";
import tanweer from "../../assets/images/about/tanweer.jpg";
import cipri from "../../assets/images/team/4.png";

const TeamBio = () => {
    return (
        <section className="commonSection" >
            <div className="container text-sm-center">

                <div className="row">
                    <div className="col-md-6 my-4">
                        <div className="team_bio_image">
                            <img src={fey}  className="team_bio_image_container" alt=""/>
                        </div>
                        <h4 className="sub_title">CEO & CO-FOUNDER</h4>
                        <h2>FEY</h2>

                        <p>Fey leads Cyber Hybrid and has over 10 years of experience in the finance industry.
                            He is the managing director of NKM Finance, a highly automated accounting and tax firm.
                            With his dual role, he will merge all winning business principles and fuse it into the
                            core of Cyber Hybrid. Fey advised numerous companies to grow from start-up/scale-up to
                            corporate ascending companies. In his free time, he is what we call a globe trotter.</p>
                    </div>
                    <div className="col-md-6 my-4">
                        <div className="team_bio_image_container">
                            <img src={raihan} className="team_bio_image_container" alt=""/>
                        </div>
                        <h4 className="sub_title">COO & CO-FOUNDER</h4>
                        <h2>Raihaan</h2>
                        <p>Raihaan is the COO  of our company, with a great sense of human knowledge combined with
                            a smart creative mind and empathy, he will bind the core of our team. As a transport
                            professional, he has years of experience in worldwide customer relationship management.
                            Besides that he also works as a freelance personal trainer, spending time to make his
                            clients get in the best shape possible..</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 my-4">
                        <div className="team_bio_image">
                            <img src={tanweer} className="team_bio_image_container" alt=""/>
                        </div>
                        <h4 className="sub_title">CSO & CO-FOUNDER</h4>
                        <h2>TANWEER</h2>
                        <p>Tanweer is the Chief Strategy Officer of Cyber Hybrid. As CSO he is responsible for assisting
                            the chief executive officer (CEO) with developing, communicating, executing, and sustaining
                            corporate strategic initiatives. As an experienced finance professional, Tanweer has managed
                            complex operations in his roles as a consultant for various financial institutions and is determined
                            to make Cyber Hybrid a Fortune 500 company. Outside of business hours, you would most likely
                            find him at the gym..</p>
                    </div>
                    <div className="col-md-6 my-4">
                        <div className="team_bio_image">
                            <img src={cipri} className="team_bio_image_container"  alt=""/>
                        </div>
                        <h4 className="sub_title">CTO & CO-FOUNDER</h4>

                        <h2>CIPRIAN</h2>
                        <p>Ciprian is a geek. He loves everything related to computers. He spends eight to ten hours a
                            day in front of a computer screen coding, debugging, or discussing coding practices and methods
                            with other programmers. When he is not programming, he is helping others learn to program.
                            In his free time, he loves to travel and exercise to stay healthy and in shape..</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TeamBio;
