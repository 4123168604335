import React from 'react';

import {ReactComponent as Innovative} from '../../assets/images/about/ic_innovative.svg'
import {ReactComponent as Sustainable} from '../../assets/images/about/ic_sustainable.svg'
import {ReactComponent as Accountable} from '../../assets/images/about/ic_accountable.svg'
import {ReactComponent as Honest} from '../../assets/images/about/ic_honest.svg'
import {ReactComponent as Efficient} from '../../assets/images/about/ic_efficient.svg'
import {ReactComponent as Transparent} from '../../assets/images/about/ic_transparent.svg'


const Values = () => {
    return (
        <>
            <section className="values_bg commonSection pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">

                            <h2 className="sec_title white">VALUES
                            </h2>
                            <br/>
                            {/*<p className="sec_desc color_aaa">*/}
                            {/*    We want to hybrydize and improve the app revolution, conjoin and globalize our market.*/}
                            {/*    Let's change for better and create a global market for web applications.*/}
                            {/*</p>*/}
                        </div>
                    </div>
                    <div className="row custom_column">
                        <div className="col-md-2">
                            <a href="#" className="icon_box_1 text-center">
                                <div className="flipper">
                                    <div className="front">
                                        <div className="icon">
                                          <Innovative/>
                                            <h3>Innovative</h3>
                                        </div>
                                    </div>
                                    <div className="back">
                                        <div className="icon">
                                          <Innovative/>
                                            <h3>Innovative</h3>
                                        </div>

                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-md-2">
                            <a href="#" className="icon_box_1 text-center">
                                <div className="flipper">
                                    <div className="front">
                                      <div className="icon">
                                        <Sustainable/>
                                          <h3>Sustainable</h3>
                                      </div>
                                    </div>
                                    <div className="back">
                                      <div className="icon">
                                        <Sustainable/>
                                          <h3>Sustainable</h3>
                                      </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-2">
                            <a href="#" className="icon_box_1 text-center">
                                <div className="flipper">
                                    <div className="front">
                                        <div className="icon">
                                            <Accountable/>
                                            <h3>Accountable</h3>
                                        </div>
                                    </div>
                                    <div className="back">
                                        <div className="icon">
                                            <Accountable/>
                                            <h3>Accountable</h3>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-2">
                            <a href="#" className="icon_box_1 text-center">
                                <div className="flipper">
                                    <div className="front">
                                        <div className="icon">
                                            <Honest/>
                                            <h3>Honest</h3>
                                        </div>
                                    </div>
                                    <div className="back">
                                        <div className="icon">
                                            <Honest/>
                                            <h3>Honest</h3>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-2">
                            <a href="#" className="icon_box_1 text-center">
                                <div className="flipper">
                                    <div className="front">
                                        <div className="icon">
                                            <Efficient/>
                                            <h3>Efficient</h3>
                                        </div>
                                    </div>
                                    <div className="back">
                                        <div className="icon">
                                            <Efficient/>
                                            <h3>Efficient</h3>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-2">
                            <a href="#" className="icon_box_1 text-center">
                                <div className="flipper">
                                    <div className="front">
                                        <div className="icon">
                                            <Transparent/>
                                            <h3>Transparent</h3>
                                        </div>
                                    </div>
                                    <div className="back">
                                        <div className="icon">
                                            <Transparent/>
                                            <h3>Transparent</h3>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Values;
