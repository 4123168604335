import React from 'react';
import axios from 'axios';
import {useForm} from 'react-hook-form'
import {Link} from "react-router-dom";

const ContactForm = (props) => {
    const {register, handleSubmit, errors} = useForm();

    const renderMessage = () => {
        let message = 'Send Message';
        if (Object.keys(errors).length) {
            message = 'Failed!';
        } else if (props.submitError) {
            message = 'Try again.'
        } else if (props.submitSuccess) {
            message = 'Thank you!'
        }

        return <>
            <span>{message}</span>
        </>
    }

    return (
        <>
            <section className="commonSection ContactPage">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h4 className="sub_title">CONTACT US</h4>
                            <h2 className="sec_title">LET'S CHANGE FOR BETTER</h2>
                            {/*<p className="sec_desc">*/}
                            {/*    We make app development affordable for everyone*/}
                            {/*</p>*/}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 col-lg-offset-2 col-sm-12 col-md-10 col-md-offset-1">
                            <form onSubmit={handleSubmit(props.onSubmit)} className="contactFrom" id="contactForm">
                                <div className="row">
                                    <div className="col-lg-6 col-sm-6">
                                        <input
                                            className={['input-form required', errors.f_name && 'reqError'].join(' ')}
                                            type="text" name="f_name" id="f_name" ref={register({required: true})}
                                            placeholder="First Name"/>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <input
                                            className={['input-form required', errors.l_name && 'reqError'].join(' ')}
                                            type="text" name="l_name" id="l_name" ref={register({required: true})}
                                            placeholder="Last Name"/>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <input className={['input-form required', errors.email && 'reqError'].join(' ')}
                                               type="email" name="email" id="email" ref={register({required: true})}
                                               placeholder="Email Address"/>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <input className={['input-form required', errors.phone && 'reqError'].join(' ')}
                                               type="text" name="phone" id="phone" ref={register({required: true})}
                                               placeholder="Phone Number"/>
                                    </div>
                                    <div className="col-lg-12 col-sm-12">
                                        <textarea
                                            className={['input-form required', errors.con_message && 'reqError'].join(' ')}
                                            name="con_message" id="con_message" ref={register({required: true})}
                                            placeholder="Write Message"></textarea>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 text-center">
                                        <p className="sec_desc">
                                            By sending us the message you agree with our <Link to="/privacy"> <span
                                            id="privacy_policy_span">Privacy Policy</span></Link>
                                        </p>
                                    </div>
                                </div>
                                <button className="common_btn red_bg" type="submit" id="con_submit"
                                        disabled={props.submitSuccess}>
                                    {renderMessage()}
                                </button>
                            </form>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactForm;
