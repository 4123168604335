import React from 'react';
import {TextAnimation} from "../TextAnimation";

const HomePageBanner = () => {
    return (
        <>
            <section className="pageBanner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="banner_content text-center">
                                <h2>We
                                    <TextAnimation withSpace={true} className="css-typing">
                                        <>audit</>
                                        <>develop</>
                                        <>maintain</>
                                        <>consult</>
                                    </TextAnimation>
                                     to <br/>
                                    <TextAnimation withSpace={true} className="css-typing" delay={500}>
                                        <>secure</>
                                        <>grow</>
                                        <>protect</>
                                        <>innovate</>
                                    </TextAnimation>
                                    your business
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
        ;
};

export default HomePageBanner;
