import React from 'react';
import {Link} from 'react-router-dom';
import logo from "../../assets/images/logo-header.png";

const Footer = () => {
    return (
        <>
            <footer className="footer_1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-sm-6 col-md-5">
                            <aside className="widget aboutwidget">
                                <Link to="/"><img src={logo} alt="cyber hybrid logo"/></Link>
                                <Link to="#"><img src="src/assets/images/logo.png" alt=""/></Link>

                                <div className="row">
                                    <div className="col-md-6">
                                        <Link to="/"><p>Home</p></Link>
                                        <Link to="/about"><p>About us</p></Link>
                                        <Link to="/portofolio"><p>Portofolio</p></Link>
                                        <Link to="#"><p>Services</p></Link>
                                        <Link to="/services/audit"><p>- Audit</p></Link>
                                        <Link to="/services/it-development"><p>-Development</p></Link>
                                        <Link to="/services/maintenance"><p>- Maintenance</p></Link>
                                        <Link to="/services/consulting"><p>- Consultancy</p></Link>
                                    </div>
                                    <div className="col-md-6">
                                        <Link to="/cookie-policy"><p>Cookie Policy</p></Link>
                                        <Link to="/privacy"><p>Privacy Policy</p></Link>
                                        <Link to="/disclaimer"><p>Disclaimer</p></Link>
                                        <Link to="/contact"><p>Contact us</p></Link></div>
                                </div>

                            </aside>
                        </div>
                        <div className="col-lg-4 col-sm-4 col-md-4">
                            <aside className="widget contact_widgets">
                                <h3 className="widget_title">contact</h3>
                                <p>
                                    The Hague Office<br/>
                                    World Trade Center The Hague<br/>
                                    Prinses Margrietplantsoen 33<br/>2595 AM The Hague
                                </p>
                                <p>Email: <a href="mailto:info@cyberhybrid.com">info@cyberhybrid.com</a></p>
                                <p>Phone: <a href="tel:+31851304002">+31851304002</a></p>
                            </aside>
                        </div>
                        <div className="col-lg-3 col-sm-2 col-md-3">
                            <aside className="widget social_widget">
                                <h3 className="widget_title">social</h3>
                                <ul>
                                    <li><a href="https://twitter.com/hybrid_cyber"><i className="fa fa-twitter"></i>Twitter</a>
                                    </li>
                                    <li><a href="https://www.facebook.com/Cyber-Hybrid-103051907856184/"><i
                                        className="fa fa-facebook-square"></i>Facebook</a></li>
                                    <li><a href=""><i className="fa fa-youtube-play"></i>Youtube</a></li>
                                    <li><a href="https://www.linkedin.com/company/31452855/"><i
                                        className="fa fa-linkedin"></i>Linkedin</a></li>
                                    <li><a href="https://www.instagram.com/cyberhybridnl/"><i
                                        className="fa fa-instagram"></i>Instagram</a></li>

                                </ul>
                            </aside>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12 text-center">
                            <div className="copyright">
                                © CYBER HYBRID COPYRIGHT 2020
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
