import React from 'react';

import {ReactComponent as NativeAppDevelopmentIcon} from '../../assets/images/services/native-app-development.svg'
import {ReactComponent as ProductMarketStrategyIcon} from '../../assets/images/services/product-market-strategy.svg'
import {ReactComponent as QaTestingIcon} from '../../assets/images/services/qa-testing.svg'
import {ReactComponent as UiUxDesignIcon}  from '../../assets/images/services/ui-ux-design.svg'

const Services = () => {
    return (
        <>
            <section className="service_section commonSection flipper-min-height">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">

                            <h2 className="sec_title white">FIND OUT HOW WE CAN HELP YOU
                            </h2>
                            <h4 className="sub_title red_color">Services we are offering</h4>
                            <br/>
                            {/*<p className="sec_desc color_aaa">*/}
                            {/*    We want to hybrydize and improve the app revolution, conjoin and globalize our market.*/}
                            {/*    Let's change for better and create a global market for web applications.*/}
                            {/*</p>*/}
                        </div>
                    </div>
                    <div className="row custom_column">

                        <div className="col-md-3">
                            <a href="#" className="icon_box_1 text-center">
                                <div className="flipper">
                                    <div className="front">
                                      <div className="icon">
                                        <UiUxDesignIcon/>
                                      </div>
                                        <h3>AUDIT</h3>
                                        <h5>
                                            No matter the size and type of your organization, an audit offers crucial insights
                                        </h5>
                                    </div>
                                    <div className="back">
                                      <div className="icon">
                                        <UiUxDesignIcon/>
                                      </div>
                                        <h3>we audit to <b>secure</b> your business</h3>
                                        <ul className={"text-left bullets"}>
                                            <li>Achievement of operational goals</li>
                                            <li>Reliability and integrity of information</li>
                                            <li>Safeguarding of assets.</li>
                                            <li>Effective and efficient use of resources</li>
                                            <li>Compliance with laws and regulations</li>
                                        </ul>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3">
                            <a href="#" className="icon_box_1 text-center">
                                <div className="flipper">
                                    <div className="front ">
                                        <div className="icon">
                                            <ProductMarketStrategyIcon/>
                                        </div>
                                        <h3>DEVELOPMENT</h3>
                                        <h5>We cover all bits and pieces needed to develop a top-notch, innovative online product.</h5>
                                    </div>
                                    <div className="back">
                                        <div className="icon">
                                            <ProductMarketStrategyIcon/>
                                        </div>
                                        <h3>we develop to <b>grow</b> your business</h3>
                                        <ul className={"text-left bullets"}>
                                            <li>UX / UI Design</li>
                                            <li>Design Prototyping</li>
                                            <li>iOS / Android Development</li>
                                            <li>Product Consulting</li>
                                            <li>Branding</li>
                                        </ul>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3">
                            <a href="#" className="icon_box_1 text-center">
                                <div className="flipper">
                                    <div className="front">
                                      <div className="icon">
                                        <NativeAppDevelopmentIcon/>
                                      </div>
                                        <h3>Maintenance</h3>
                                        <h5>When it comes to your business, there is nothing worse than downtime because your technology isn't working properly.</h5>
                                    </div>
                                    <div className="back">
                                      <div className="icon">
                                        <NativeAppDevelopmentIcon/>
                                      </div>
                                        <h3>we maintain to <b>protect</b> your business</h3>
                                        <ul className={"text-left bullets"}>
                                            <li>Functional reliability</li>
                                            <li>Enable service quality</li>
                                            <li>Maximize life of equipment</li>
                                            <li>Minimize total costs to repair</li>
                                            <li>Minimize delays to production</li>
                                        </ul>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-md-3">
                            <a href="#" className="icon_box_1 text-center">
                                <div className="flipper">
                                    <div className="front">
                                      <div className="icon">
                                        <QaTestingIcon/>
                                      </div>
                                        <h3>consultancy</h3>
                                        <h5>Every business can use some help to find ways to stand out, tighten their operations, and increase their revenues while keeping expenses low.
                                        </h5>
                                    </div>
                                    <div className="back">
                                      <div className="icon">
                                        <QaTestingIcon/>
                                      </div>
                                        <h3>we Consult to <b>innovate</b> your business</h3>
                                        <ul className={"text-left bullets"}>
                                            <li>Profitability</li>
                                            <li>Growth</li>
                                            <li>Stability</li>
                                            <li>Efficiency</li>
                                            <li>Survival.</li>
                                        </ul>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Services;
